import React from 'react';
import Loader from '@Components/ui/loader';
import { useFetchBookingSeries } from './booking-form-series-hook';

const ConfirmUnpublishSeries = ({ onCancel, onConfirm, progress }) => {
  const { loading, hasCustomers } = useFetchBookingSeries();

  return (
    <form autoComplete="off" onSubmit={onConfirm} className={progress ? 'disabled' : ''}>
      {loading && <Loader />}
      <p className="mb3">
        <strong>Bekräfta avpublicering av samtliga pass i serien.</strong>
      </p>

      {hasCustomers && (
        <p className="text-info mb2">
          <i className="fa fa-exclamation-circle mr1" />
          Observera att det finns bokningar i serien
        </p>
      )}

      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex={2} onClick={onCancel}>Avbryt</button> &nbsp;
        <button type="submit" className="btn-save" tabIndex={1}>{progress ? 'Avpublicerar...' : 'Avpublicera'}</button>
      </div>
    </form>
  );
};

export default ConfirmUnpublishSeries;
