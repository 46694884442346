import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

const BookingClassPublish = ({ services }) => {
  if (!services || services.isEmpty()) {
    return null;
  }
  return (
    <div className="booking-form-block publish">
      <div className="form-group">
        <div className="checkbox">
          <label>
            <Field name="publishClass" component="input" type="checkbox" value="true" />
            Publicera pass och gör tillgängligt för bokning direkt
          </label>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const initialValues = {
    publishClass: true
  };

  return {
    services: state.bkf.get('services'),
    initialValues
  };
};

export default connect(mapStateToProps)(reduxForm({
  form: 'bkf-class-publish',
  destroyOnUnmount: false
})(BookingClassPublish));
