import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sms, email } from '@Utils/preference-keys';
import PhoneUtil from '@Utils/phone-util';
import { getNewPriceCategory } from '@State/price-selectors';
import { isClassWithBookings } from '@Utils/booking-util';
import { getResourceFromColIdx } from '@State/calendar-selectors';
import { getSmsEnabled } from '@State/selectors';
import ProgressButton from '@Components/ui/progress-button';
import { txt } from '@Utils/i18n-util';
import msg from './confirm-move.msg';
import ConfirmChangeSeries from './confirm-change-series';

class ConfirmMove extends React.Component {
  static propTypes = {

    startTime: PropTypes.object.isRequired,
    customerEmail: PropTypes.string,
    customerPhoneNumber: PropTypes.string,
    copyOnPaste: PropTypes.bool,

    enableSmsConfirmations: PropTypes.bool.isRequired,
    enableEmailConfirmations: PropTypes.bool.isRequired,
    defaultOptionSmsConfirm: PropTypes.bool.isRequired,
    defaultOptionEmailConfirm: PropTypes.bool.isRequired,

    onClosePopover: PropTypes.func.isRequired,
    onConfirmMove: PropTypes.func.isRequired

  };

  constructor(props) {
    super(props);
    this.state = {
      sendSmsConfirmation: props.customerPhoneNumber && props.smsEnabled && props.defaultOptionSmsConfirm,
      sendEmailConfirmation: props.customerEmail && props.defaultOptionEmailConfirm,
      updatePrice: false,
      updateSeries: 'Single'
    };
  }

  handleSmsConfirmChange = (ev) => {
    this.setState({ sendSmsConfirmation: ev.target.checked });
  };

  handleEmailConfirmationChange = (ev) => {
    this.setState({ sendEmailConfirmation: ev.target.checked });
  };

  handleUpdatePriceChange = (ev) => {
    this.setState({ updatePrice: ev.target.checked });
  };

  handleUpdateSeriesChange = (ev) => {
    this.setState({ updateSeries: ev.target.value });
  };

  handleConfirmMove = () => {
    return this.props.onConfirmMove(this.state);
  };

  render() {
    const {
      startTime, classWithBookings, customerEmail, customerPhoneNumber,
      columnResource, booking, newPriceCategory, phoneMode, series
    } = this.props;
    const { sendSmsConfirmation, sendEmailConfirmation, updatePrice, updateSeries } = this.state;

    const className = phoneMode ? 'modal-body' : 'Popover-content';
    const primaryResource = booking.resources.find(r => r.primary);
    const resource = columnResource?.id === primaryResource?.id ? null : columnResource?.name;
    const time = booking.startTime.isSame(startTime) ? null : startTime.format('LLLL');

    const destination = [resource, time].filter(d => d).join(', ');
    const promptText = this.props.copyOnPaste
      ? txt(msg.txtConfirmCopy, { destination })
      : txt(msg.txtConfirmMove, { destination });

    return (
      <div className={className}>
        <div className="booking-form-block nopointer">
          <p>
            <strong>
              {promptText}
            </strong>
          </p>
          {series && (
            <ConfirmChangeSeries updateSeries={updateSeries} onChange={this.handleUpdateSeriesChange} />
          )}
          {newPriceCategory && (
            <>
              <p className="text-info mb2">
                <i className="fa fa-exclamation-circle mr1" />
                {txt(msg.txtNewPriceCategory)}: {txt(msg[newPriceCategory])}
              </p>
              <div className="checkbox">
                <label>
                  <input type="checkbox" checked={updatePrice} onChange={this.handleUpdatePriceChange} />
                  {txt(msg.lblUpdatePrice)}
                </label>
              </div>
            </>
          )}

          {this.shouldRenderSms() && (
            <div className="checkbox">
              <label>
                <input type="checkbox" checked={sendSmsConfirmation} onChange={this.handleSmsConfirmChange} />
                {txt(msg.lblSendSMS, {
                  phoneNumber: classWithBookings
                    ? txt(msg.lblAllCustomers)
                    : PhoneUtil.formatPhoneNumber(customerPhoneNumber)
                })}
              </label>
            </div>
          )}

          {this.shouldRenderEmail() && (
            <div className="checkbox">
              <label>
                <input type="checkbox" checked={sendEmailConfirmation} onChange={this.handleEmailConfirmationChange} />
                {txt(msg.lblSendEmail, {
                  email: classWithBookings
                    ? txt(msg.lblAllCustomers)
                    : customerEmail
                })}
              </label>
            </div>
          )}
        </div>

        <div className="text-right">
          <button type="button" className="btn btn-default" onClick={this.props.onClosePopover}>{txt(msg.btnCancel)}</button> &nbsp;
          <ProgressButton small primary onClick={this.handleConfirmMove}>
            {this.props.copyOnPaste ? txt(msg.btnCopy) : txt(msg.btnMove)}
          </ProgressButton>
        </div>
      </div>
    );
  }

  shouldRenderSms() {
    const { customerPhoneNumber, enableSmsConfirmations, classWithBookings } = this.props;
    return enableSmsConfirmations && (classWithBookings || customerPhoneNumber);
  }

  shouldRenderEmail() {
    const { customerEmail, enableEmailConfirmations, classWithBookings } = this.props;
    return enableEmailConfirmations && (classWithBookings || customerEmail);
  }
}

const mapStateToProps = (state, ownProps) => {
  const { locationConfig, locationFeatures, bookingsById } = state;
  const booking = bookingsById.get(ownProps.bookingId);
  const smsEnabled = getSmsEnabled(state);

  return {
    booking,
    smsEnabled,
    series: booking.bookingSeries,
    classWithBookings: isClassWithBookings(booking),
    enableEmailConfirmations: locationFeatures.get('EnableEmailConfirmations'),
    enableSmsConfirmations: smsEnabled && locationFeatures.get('EnableSmsConfirmations'),
    defaultOptionSmsConfirm: locationConfig.get(sms.defaultOptionSmsConfirm),
    defaultOptionEmailConfirm: locationConfig.get(email.defaultOptionEmailConfirm),
    columnResource: getResourceFromColIdx(state, ownProps),
    newPriceCategory: getNewPriceCategory(state, ownProps)
  };
};

export default connect(mapStateToProps)(ConfirmMove);
