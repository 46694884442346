import React from 'react';

const ConfirmPublishSeries = ({ onCancel, onConfirm, progress }) => {
  return (
    <form autoComplete="off" onSubmit={onConfirm} className={progress ? 'disabled' : ''}>
      <p className="mb3">
        <strong>Vill du publicera passet och tillåta bokning online?</strong>
      </p>

      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex={2} onClick={onCancel}>Avbryt</button> &nbsp;
        <button type="submit" className="btn-save" tabIndex={1}>{progress ? 'Publicerar...' : 'Publicera'}</button>
      </div>
    </form>
  );
};

export default ConfirmPublishSeries;
