import { defineMessages } from 'react-intl';

export default defineMessages({
  txtConfirmMove: {
    id: 'calendar.bkf.confirm-move.txtConfirmMove',
    defaultMessage: 'Confirm move to {destination}?'
  },
  txtConfirmCopy: {
    id: 'calendar.bkf.confirm-move.txtConfirmCopy',
    defaultMessage: 'Copy booking to {destination}?'
  },
  lblSendSMS: {
    id: 'calendar.bkf.confirm-move.lblSendSMS',
    defaultMessage: 'SMS confirmation to {phoneNumber}'
  },
  lblSendEmail: {
    id: 'calendar.bkf.confirm-move.lblSendEmail',
    defaultMessage: 'Email confirmation to {email}'
  },
  lblUpdatePrice: {
    id: 'calendar.bkf.confirm-move.lblUpdatePrice',
    defaultMessage: 'Update price to the new price category'
  },
  lblAllCustomers: {
    id: 'calendar.bkf.confirm-move.lblAllCustomers',
    defaultMessage: 'all customers'
  },
  btnCancel: {
    id: 'calendar.bkf.confirm-move.btnCancel',
    defaultMessage: 'Cancel'
  },
  btnCopy: {
    id: 'calendar.bkf.confirm-move.btnCopy',
    defaultMessage: 'Copy'
  },
  btnMove: {
    id: 'calendar.bkf.confirm-move.btnMove',
    defaultMessage: 'Move'
  },
  txtNewPriceCategory: {
    id: 'calendar.bkf.confirm-move.txtNewPriceCategory',
    defaultMessage: 'Booking is moved to a new price category'
  },
  price: {
    id: 'calendar.bkf.confirm-move.price',
    defaultMessage: 'Workday'
  },
  priceEvening: {
    id: 'calendar.bkf.confirm-move.priceEvening',
    defaultMessage: 'Evening'
  },
  priceHoliday: {
    id: 'calendar.bkf.confirm-move.priceHoliday',
    defaultMessage: 'Holiday'
  },
  txtMoveSeries: {
    id: 'calendar.bkf.confirm-move.txtMoveSeries',
    defaultMessage: 'Booking is part of a series'
  }
});
