import React from 'react';
import { txt } from '@Utils/i18n-util';
import msg from './confirm-move.msg';

const ConfirmChangeSeries = ({ updateSeries, onChange }) => (
  <>
    <p className="text-info mb2">
      <i className="fa fa-exclamation-circle mr1" />
      {txt(msg.txtMoveSeries)}
    </p>
    <div className="radio">
      <label>
        <input type="radio" value="Single" checked={updateSeries === 'Single'} onChange={onChange} />
        Flytta endast denna bokning
      </label>
    </div>
    <div className="radio">
      <label>
        <input type="radio" value="Future" checked={updateSeries === 'Future'} onChange={onChange} />
        Flytta alla framtida bokningar
      </label>
    </div>
  </>
);

export default ConfirmChangeSeries;
