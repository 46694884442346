import React, { useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import { toggleBKFModal } from '@State/bkf/actions';
import { isBookingNew } from '@Utils/booking-util';
import { bkfModals } from '@State/bkf/constants';
import SelectField from '@Components/inputs/select-field';
import { getInitialValuesFromRruleString, getRruleDescription, getRruleDays } from './recurring-util';

const BookingRecurring = ({ id, recurrenceRule, onShowSeries, repeat = 'WEEKLY', days = [], end = 'UNTIL' }) => {
  const dispatch = useDispatch();

  const isNew = isBookingNew(id);
  const [editRule, setEditRule] = useState(isNew);

  const rruleDays = useMemo(getRruleDays, []);

  const toggleDay = (day) => {
    const newDays = days.includes(day)
      ? days.filter(d => d !== day)
      : [...days, day];
    dispatch(change('bkf-recurring', 'days', newDays));
  };

  if (editRule) {
    return (
      <div className="booking-form-block form">
        <div className="row tight">
          <Field
            name="repeat"
            label="Repetera"
            className="col-xs-6"
            component={SelectField}
            autoFocus
          >
            <option value="WEEKLY">Veckovis</option>
            <option value="DAILY">Dagligen</option>
          </Field>
          <div className="form-group col-xs-6">
            <label className="control-label">Intervall</label>
            <Field
              name="interval"
              component="input"
              className="form-control"
            />
          </div>
        </div>
        {repeat === 'WEEKLY' && (
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">Veckodagar</label>
              <div className="btn-group">
                {rruleDays.map(({ day, name }) => (
                  <button
                    key={day}
                    className={days.includes(day) ? 'btn btn-primary' : 'btn btn-default'}
                    onClick={() => toggleDay(day)}
                  >
                    {name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="row tight">
          <Field
            name="end"
            label="Slutar"
            className="col-xs-6"
            component={SelectField}
          >
            <option value="UNTIL">Vid datum</option>
            <option value="COUNT">Efter antal</option>
          </Field>
          <div className="form-group col-xs-6">
            <label className="control-label">&nbsp;</label>
            {end === 'UNTIL' && (
              <Field
                name="until"
                component="input"
                type="date"
                className="form-control"
              />
            )}
            {end === 'COUNT' && (
              <Field
                name="count"
                component="input"
                className="form-control"
                required
              />
            )}
          </div>
        </div>
        {!isNew && (
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={() => setEditRule(false)}>Stäng</button>
          </div>
        )}
      </div>
    );
  }

  if (recurrenceRule) {
    return (
      <div className="booking-form-block one-line" onClick={() => onShowSeries()}>
        <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
          <button className="btn-label" tabIndex={-1} onClick={() => onShowSeries()}>
            Visa mer
          </button>
        </div>
        <div>
          <i className="fa fa-repeat mr1" />
          {getRruleDescription(recurrenceRule)}
        </div>
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  const { bkf } = state;
  const { recurrenceRule } = bkf.get('bookingSeries') || {};
  const initialValues = getInitialValuesFromRruleString(recurrenceRule);

  return {
    id: bkf.get('id'),
    recurrenceRule,
    initialValues,
    ...getFormValues('bkf-recurring')(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowSeries: () => dispatch(toggleBKFModal(bkfModals.series, true))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bkf-recurring',
  destroyOnUnmount: false
})(BookingRecurring));
