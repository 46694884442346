import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBookingSeries } from '@State/bkf/actions';

export function useFetchBookingSeries() {
  const dispatch = useDispatch();
  const { seriesId } = useSelector(state => state.bkf.get('bookingSeries'));

  const [series, setSeries] = useState(null);
  const [loading, setLoading] = useState(null);

  const hasCustomers = series?.bookings?.some((b) => b.booked > 0);

  useEffect(() => {
    if (!seriesId) {
      return;
    }
    setLoading(true);
    dispatch(fetchBookingSeries(seriesId))
      .then((data) => setSeries(data))
      .finally(() => setLoading(false));
  }, [seriesId]);

  return {
    seriesId,
    loading,
    ...series,
    hasCustomers
  };
}
